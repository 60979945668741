<template>
  <div>
    <!-- <el-carousel :height="imgHeight + 'px'">
      <el-carousel-item v-for="item in 4" :key="item">
        <img
          ref="imgHeight"
          style="width: 100%"
          src="../assets/banner.png"
          mode="widthFix"
        />
      </el-carousel-item>
    </el-carousel> -->
     <img class="banner" style="width: 100%" :src="bander" alt="" />
    <div class="box">
      <!-- 产品服务 | PRODUCTS -->
      <div
        style="
          display: flex;
          margin: 0 20px;
          margin-top: 104px;

          align-items: center;
          justify-content: center;
        "
      >
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/left.png"
          alt=""
        />
        <div
          style="
            font-size: 34px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #333333;
            margin: 0 20px;
          "
        >
        企业案例| CASES
        </div>
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/right.png"
          alt=""
        />
      </div>
      <div>
        <div
          style="
            display: flex;
            margin-top: 70px;
            margin-bottom: 40px;
            justify-content: flex-end;
          "
        >
          <!-- <div
            @click="trage(item.name,item.id)"
            :class="
              'trage ' + (chooseList.indexOf(item.name) == -1 ? '' : 'trages')
            "
            v-for="(item, index) in list"
            :key="index"
          >
            {{ item.name }}
          </div> -->
        </div>
      </div>
      <div
        style="
          display: flex;
          margin-bottom: 20px;
          width: 1200px;
          height: 192px;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 1px solid #cccccc;
          padding: 24px;
        "
        v-for="(item, index) in list2"
        :key="index"
      >
        <img
          style="
            width: 240px;
            height: 140px;
            background: #e40111;
            border-radius: 8px 8px 8px 8px;
          "
          :src="item.images"
          alt=""
        />
        <div style="margin-left: 60px; margin-left: 32px">
          <div
            style="
              font-size: 24px;
              font-family: PingFang SC-Heavy, PingFang SC;
              font-weight: 800;
              color: #333333;
            "
          >
          {{item.case_name}}
          </div>
           <!-- v-html="item.content" -->
          <div style="margin: 20px 0" class="s1">
           {{item.case_blurb}}
          </div>
        </div>
        <div style="margin-left: 40px;margin-top: 20px;">
          <div style="font-size: 28px;
font-family: PingFang SC-Heavy, PingFang SC;
font-weight: 800;
color: #333333;">{{item.time}}</div>
          <div  style="margin-top: 10px;margin-bottom: 20px;font-size: 16px;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #333333;">{{item.year}}年</div>
          <div
            style="
              width: 91px;
              height: 33px;
              border-radius: 8px 8px 8px 8px;
              opacity: 1;
              border: 1px solid #ba1c2b;
              font-size: 14px;
              font-family: PingFang SC-Heavy, PingFang SC;
              font-weight: 800;
              color: #ba1c2b;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            @click="deatile(item.id)"
          >
            查看详情
          </div>
        </div>
      </div>
	  <div style="display: flex;justify-content: flex-end;">
		   <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[ 10, 30, 40,70]"
      :page-size="limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
</el-pagination>
	  </div>
	
    </div>
  </div>
</template>
<script>
import url from "../request/api";
export default {
  data() {
    return {
      ids:"",
      imgHeight: "",
      // list: [
      //   // { name: "全部" },
      //   // { name: "公司动态" },
      //   // { name: "产品动态" },
      //   // { name: "新闻中心" },
      // ],
      chooseList: [],
       imgurl: url.baseUrl,
       list2:[],
       page:1,
       limit:10,
       total:0,
       bander:[],
      id:false
    };
  },
  mounted() { 
    this.id=this.$route.query.lei
    this.get_banner();
    // this.lists()
      this.chilelist()
    this.banderlist()
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener(
      "resize",
      () => {
        this.get_banner();
      },
      false
    );
  },
     watch: {
    $route(to, from) {
      this.id=this.$route.query.lei
      // this.lists()
      this.chilelist()
     console.log(this.id,'212121id');

    },
  },
  methods: {
     banderlist(){
  this.$https
          .get(`/api/index/banner?name=news_banner`, )
          .then((res) => {
            
            console.log(res);
            this.bander = res.data;
        });
    },
    
  // lists(){
      
  //        this.$https
  //       .get(`/api/news/index`)
  //       .then((res) => {
  //        console.log(res);
  //        res.data.news_cate.forEach(element => {
  //          element.name=element.news_cate
  //         if(this.id==element.id){
  //           this.chooseList=element.news_cate
  //         }else if(!this.id){
  //           this.chooseList=res.data.news_cate[0].news_cate
  //         }
  //        });
  //        this.list=res.data.news_cate
  //        if(this.id){
  //            this.ids=this.id
         
  //        this.chilelist(this.id)
  //        }else if(!this.id){
  //            this.ids=res.data.news_cate[0].id
         
  //        this.chilelist(res.data.news_cate[0].id)
  //        }
         
  //       });
  //   },
     chilelist(){
          this.$https
        .get(`/api/cases/cases_list?page=${this.page}&limit=${this.limit}`,)
        .then((res) => {
         console.log(res);
         res.data.cases.forEach(element => {
           
           element.images=this.imgurl+element.image
         });
         this.list2=res.data.cases

         this.total=res.data.count

         
        
        });
    },
	    handleSizeChange(val){
        this.limit=val
        this.chilelist(this.ids)
    },
       handleCurrentChange(val){
          this.page=val
            this.chilelist(this.ids)
    },
    deatile(id) {
      this.$router.push({
        path: "/life",
       query: {detailsid: id,path:'/about_us',name:'企业案例'}
        
      });
      console.log(index);
    },
    trage(item,id) {
      this.ids=id
      this.chooseList = [];
      if (this.chooseList.indexOf(item) == -1) {
        this.chooseList.push(item);
        this.chilelist(id)
      } else {
        this.chooseList.splice(this.chooseList.indexOf(item), 1);
      }
    },
    changenav(path, index) {
      this.$router.push({
        path: path,
      });
    },
    get_banner() {
      //获取到图片数据之后
      this.$nextTick(function () {
        // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
        this.imgHeight = (document.body.clientWidth * 480) / 1920;
      });
    },
  },
  destroyed() {
    //切换页面后销毁监听窗口事件
    window.removeEventListener("resize", this.get_banner, false);
  },
};
</script>
<style scoped>
.trage {
  width: 120px;
  height: 48px;
  /* border-radius: 8px 8px 8px 8px; */
  opacity: 1;
  border: 1px solid #ba1c2b;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ba1c2b;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 28px;
}
.trages {
  background: #ba1c2b;
  color: #fff;
}
.box {
  width: 1200px;
  margin: 56px auto;
}
.s1 {
  width: 750px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.left {
  width: 740px;
}

.left .list {
  width: 740px;
  height: 133px;
  cursor: pointer;
  margin-bottom: 48px;
}

.left .list:hover .info .title {
  color: #0049c2;
}

.left .list .img {
  width: 240px;
  height: 133px;
  overflow: hidden;
}

.left .list .img img {
  width: 240px;
}

.left .list .info {
  width: 480px;
}

.left .list .info .title {
  font-size: 20px;
  color: #222222;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.left .list .info .p {
  height: 42px;
  font-size: 16px;
  color: #666666;
  margin: 16px 0 23px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.left .list .info .time {
  font-size: 14px;
  color: #999999;
}

.right {
  width: 440px;
  background: #fbfbfc;
  box-sizing: border-box;
  padding: 24px;
}

.right .titles {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 10px;
}

.right .list {
  height: 48px;
  cursor: pointer;
}

.right .list:hover .title {
  color: #0049c2;
}

.right .list .indexs {
  width: 30px;
  font-size: 20px;
  color: #999999;
  text-align: center;
}

.right .list .title {
  width: 270px;
  font-size: 16px;
  color: #333333;
  margin: 0 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.right .list .num {
  font-size: 16px;
  color: #999999;
  margin-left: 5px;
}
</style>